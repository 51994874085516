.appSidebar {
  width: 89px;
  background-color: #ffffff;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 85px;
  z-index: 998;
  border-right: 1px solid #eaedf3;

  > ul {
    list-style: none;
    padding: 20px 0 0;
    margin: 0;

    > li.fadeInIcon {
      animation: sidebar-icon-fade-in 0.6s ease-in-out;
    }
  }

  .navLink {
    display: inline-block;
    transition: 0.15s ease-in-out;
    text-decoration: none;
    color: #8a9999;
    line-height: 1.5715;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;

    &:hover {
      background-color: #fafafc;
      box-shadow: 3px #bccbcb inset;
    }

    &:global(.active) {
      box-shadow: 3px 0 #005794 inset;
      background-color: #eff1f2;

      i {
        filter: grayscale(0%);
      }

      p {
        color: #005794;
      }
    }

    i {
      display: inline-block;
      width: 88px;
      height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      box-sizing: border-box;
      filter: grayscale(100%) brightness(2);

      &.explorer {
        background-image: url(../../images/icons/sidebar-explorer.svg);
        transform: scale(1.1);
      }

      &.search {
        background-image: url(../../images/icons/sidebar-search.svg);
        transform: scale(1.1);
      }

      &.lightbox {
        background-image: url(../../images/icons/sidebar-lightbox.svg);
        transform: scale(1.2);
      }

      &.albums {
        background-image: url(../../images/icons/sidebar-albums.svg);
        transform: scale(1.1);
      }

      &.dashboard {
        background-image: url(../../images/icons/sidebar-dashboard.svg);
        transform: scale(1.1);
      }

      &.access {
        background-image: url(../../images/icons/sidebar-access.svg);
        transform: scale(1.1);
      }

      &.settings {
        background-image: url(../../images/icons/sidebar-settings.svg);
        transform: scale(1.1);
      }

      &.photoMap {
        background-image: url(../../images/icons/photoMap.svg);
        transform: scale(1.1);
        background-size: 26px 26px;
      }
    }
  }
}

.nxpSidebarItemWrapper {
  display: block;
  text-align: center;
  width: 100%;
}

.nxpSidebarCaption {
  bottom: 10px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin: auto;
  position: relative;
  max-width: 80px;
}

.nxpSidebarItemBorder {
  border-bottom: 1px solid #eaedf3;
  margin: auto;
  width: 70%;
}

@keyframes sidebar-icon-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Note(Ray): The class name "navbar" will conflict with Bootstrap, as we have
 *            to use Bootstrap for form builder layout, rename our navbar to
 *            cpcs-navbar. 
 */
.cpcs-navbar {
  background: #1f3855;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 85px;
  padding: 0 30px;

  display: flex;
  justify-content: space-between;

  z-index: 1000;

  .navRight {
    display: flex;
    align-items: center;
  }
}

.cpcs-navbar-header {
  display: flex;
  align-items: center;
}

.cpcs-navbar-header > h1 {
  color: #ffffff;
  font-family: LucidaGrande, sans-serif;
  font-size: 40px;
  font-weight: normal;

  margin: 0;
}

.cpcs-navbar-header > h1 > small {
  color: #ffffff;
  font-size: 16px;

  display: border-box;
  margin-left: 4px;
}

.cpcs-dropdown {
  display: flex;
  align-items: center;
}

.cpcs-dropdown > div {
  float: left;
}

.cpcs-navbar-title {
  color: #ffffff;
  font-family: SegoeUI, sans-serif;
  border-left: 7px solid #0078d4;
  margin-left: 30px;
  padding: 5px 0px 5px 20px;
  height: max-content;
  align-items: baseline;
  line-height: normal;
  a {
    color: #ffffff;
    margin-left: 5px;
  }
}

.cpcs-navbar-title.ier {
  border-left-color: #6ea5ae;
}

.cpcs-navbar-title.inspection {
  border-left-color: #f36421;
}

.cpcs-navbar-title.main-portal {
  border-left-color: #0078d4;
}

.cpcs-navbar-title.site-diary {
  border-left-color: #4c617c;
}

.cpcs-navbar-title > h2 {
  font-weight: 600;
  font-size: 17px;
  margin: 0;
}

.cpcs-navbar-title > p {
  margin: 0;
}

.cpcs-navbar-title > h2 {
  font-weight: 600;
  font-size: 17px;
  margin: 0;
}

.cpcs-navbar-title > h2 > p {
  font-size: 14px;
  margin: 0 0 0 10px;
  display: inline-block;
}

.setting-dropdwon {
  color: #ffffff;
  height: 42px;
  border-left: 1px solid rgba(151, 151, 151, 0.5);
  box-sizing: content-box;
  align-items: center;
  display: flex;
}

.leaflet-marker-photo {
  border: 2px solid #fff;
  box-shadow: 3px 3px 10px #888;
}

.leaflet-marker-photo div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.leaflet-marker-photo b {
  position: absolute;
  top: -8px;
  right: -11px;
  color: #555;
  background-color: #fff;
  border-radius: 8px;
  height: 16px;
  min-width: 16px;
  line-height: 12px;
  text-align: center;
  padding: 3px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-popup-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

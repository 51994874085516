html {
  margin: 0;
  padding: 0;
  min-width: 1200px;

  body {
    margin: 0;
    padding: 0;
  }
}

.app {
  min-height: 100vh;
}

:global(.appSnackBar) {
  :global(.MuiSvgIcon-root) {
    align-self: flex-start;
  }
  .snackbarErrorIcon {
    margin-right: 8px;
  }
}

:global(.appSnackBar .MuiButton-label) {
  color: #fff;
}

:global(div.snackBarAnchorOrigin) {
  bottom: 50px;
}
